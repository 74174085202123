import React from "react";

import Layout from "../../components/legal/layout";
import Head from "../../components/head";

import styles from "../../styles/legal/legal.module.scss";

const CookiesPage = () => (
	<Layout location="cookies">
		<div className={styles.content}>
			<Head title="Cookies" />
			
			<h1 className={styles.heading}>Cookies</h1>
			<div>
				<p>A cookie is a small text file that identifies your computer on our server. Cookies themselves do not identify the individual user, only the computer used. Cookies are not used to collect personal information.</p>

				<p>At any time, the user is able to configure their web browser to accept all cookies, to notify them when a cookie is issued, or not to receive any cookies. The way the user does this depends on the web browser they use. Please consult the "Help" function on your web browser to learn more.</p>

				<p>If you accept cookies, they may remain on your computer for many years, unless you delete them. If you disable cookies, the use of sites in general may be limited. Below, you will find a list of the main cookies we use, together with a note on what they are used for.</p>

				<h2>The Cookies We Use On This Site Are:</h2>

				<p>Google Analytics: <a href="https://marketingplatform.google.com/about/analytics/terms/us/">https://marketingplatform.google.com/about/analytics/terms/us/</a></p>

				<p>Facebook pixel: <a href="https://www.facebook.com/policies/cookies/">https://www.facebook.com/policies/cookies/</a></p>

				<h2>Cookie Control</h2>

				<p>Web browsers make it possible to exert a certain amount of control over cookies by configuring the browser as you wish. The majority of web browsers allow you to block cookies, or block cookies on specific sites. Web browsers may also help you to delete cookies when you close the browser. However, this may mean that any opt-outs or preferences defined on the site will be lost. To learn more about cookies, including how to see what cookies have been created and how to manage and block them, visit the site www.allaboutcookies.org. This site contains information on how to manage your configurations for the various web browsers available. However, you should always remember that disabling cookies may affect your overall website experience, to one extent or another.</p>
			</div>
		</div>
	</Layout>
);

export default CookiesPage;
